import { Button, Stack, StackDivider } from '@chakra-ui/react'
import type { FC } from 'react'
import { RRule } from 'rrule'

import ChannelInput from '../../components/channelInput'
import HideSectionsInput from '../../components/hideSectionsInput'

import Filters from './filters'

import { ButtonRow } from '@app/shared/forms'
import { Form, RRuleInput, TextInput, useForm } from '@app/shared/rawForms'
import type { FormProps } from '@app/shared/rawForms/form'
import type { RecurringReportConfigurationQuery } from '@graphql/queries'

type Props = Omit<FormProps, 'children'> & {
  recurringReportConfiguration?: RecurringReportConfigurationQuery['recurringReportConfiguration']
  onCancel?: () => void
}

const RecurringReportConfigurationForm: FC<Props> = ({ recurringReportConfiguration, onCancel, ...formProps }) => {
  const { errors } = useForm({})

  return (
    <Form variant="drawer" {...formProps}>
      <Stack pt={4} divider={<StackDivider />} spacing={4}>
        <TextInput
          labelSize="sm"
          name="name"
          label="Report title"
          placeholder="Untitled"
          autoComplete="off"
          errors={errors}
          defaultValue={recurringReportConfiguration?.name}
          isRequired
        />
        <RRuleInput
          defaultValue={recurringReportConfiguration?.schedule}
          name="schedule"
          availableFrequencies={[RRule.DAILY, RRule.WEEKLY, RRule.MONTHLY]}
        />
        <ChannelInput report={recurringReportConfiguration} onChange={() => {}} />
        <HideSectionsInput report={recurringReportConfiguration} />
        <Filters defaultFilters={recurringReportConfiguration?.filters} />
        <ButtonRow>
          <Button onClick={onCancel} variant="secondary">
            Cancel
          </Button>
          <Button type="submit" variant="primary">
            Save
          </Button>
        </ButtonRow>
      </Stack>
    </Form>
  )
}

export default RecurringReportConfigurationForm
