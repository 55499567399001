import { IconButton, Spacer, Stack, StackDivider, Text } from '@chakra-ui/react'
import debounce from 'lodash/debounce'
import type { ChangeEvent, ChangeEventHandler, FC } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'

import ChannelInput from './components/channelInput'
import HideSectionsInput from './components/hideSectionsInput'
import ReportDatesInput from './components/reportDatesInput'
import ReportDeleteButton from './components/reportDeleteButton'

import useGetObject from '@app/hooks/useGetObject'
import DrawerHeader from '@app/pages/reports/components/drawerHeader'
import ReportPipelines from '@app/pages/reports/components/reportPipelines'
import { ReportContext } from '@app/pages/reports/home'
import Can from '@app/shared/authorization/can'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'
import { Form, TextInput, useForm } from '@app/shared/rawForms'
import { useReportUpdateMutation } from '@graphql/queries'

const Edit: FC = () => {
  const { reportId } = useParams()
  const report = useGetObject(reportId, 'report')
  const { errors, reset } = useForm({})
  const [, updateReport] = useReportUpdateMutation()

  const handleSubmit: ChangeEventHandler<HTMLFormElement> = (event) => {
    const formData = new FormData(event.target.form)
    const input = Object.fromEntries(formData.entries())

    reset()

    return updateReport({
      input: {
        ...input,
        reportId
      }
    })
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) =>
    updateReport({
      input: {
        reportId,
        [e.target.name]: e.target.value
      }
    })

  const debouncedSubmit = debounce((e) => handleSubmit(e), 200)

  if (!report) {
    return null
  }

  return (
    <ReportContext.Consumer>
      {() => (
        <Stack maxW="100%" spacing={4}>
          <DrawerHeader px={4}>
            <IconButton as={Link} aria-label="go back" icon={<FiArrowLeft />} to=".." variant="ghost" />
            <Text>Editing {report.name}</Text>
            <Spacer />
            <DrawerCloseButton size="xs" />
          </DrawerHeader>

          <Form method="post" onChange={(e: ChangeEvent<HTMLFormElement>) => debouncedSubmit(e)} variant="drawer">
            <Stack px={4} pb={4} divider={<StackDivider />} spacing={6}>
              <Stack spacing={6}>
                <TextInput
                  labelSize="sm"
                  name="name"
                  label="Report title"
                  defaultValue={report?.name}
                  placeholder="Untitled"
                  autoComplete="off"
                  errors={errors}
                  isRequired
                />
                <ReportDatesInput report={report} errors={errors} />
                <Can I="view" an="admin">
                  <ReportPipelines report={report} />
                </Can>
              </Stack>
              <ChannelInput report={report} onChange={onChange} />
              <Stack spacing={6}>
                <HideSectionsInput report={report} />
                <ReportDeleteButton reportId={report.id} />
              </Stack>
            </Stack>
          </Form>
        </Stack>
      )}
    </ReportContext.Consumer>
  )
}

export default Edit
