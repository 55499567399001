import { Stack, Text, Spacer, Button, ButtonGroup, IconButton } from '@chakra-ui/react'
import type { FC } from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { Link, useParams } from 'react-router-dom'

import ReportContent from './components/reportContent'

import useGetObject from '@app/hooks/useGetObject'
import DrawerHeader from '@app/pages/reports/components/drawerHeader'
import SendReportButton from '@app/pages/reports/components/shared/sendReportButton'
import Can from '@app/shared/authorization/can'
import DrawerCloseButton from '@app/shared/drawer/drawerCloseButton'

const Show: FC = () => {
  const { reportId } = useParams()

  const report = useGetObject(reportId, 'report')

  if (!report) {
    return null
  }

  const { updatedAt } = report
  const updated = new Date(updatedAt)

  return (
    <Stack w="3xl" maxW="100%" mx="auto" spacing={4}>
      <DrawerHeader px={4}>
        <IconButton as={Link} aria-label="go back" icon={<FiArrowLeft />} to=".." variant="ghost" />
        <Text color="subtle" fontSize="sm">
          Last updated on{' '}
          {updated.toLocaleDateString(undefined, {
            weekday: 'short',
            month: 'numeric',
            day: 'numeric',
            year: 'numeric'
          })}{' '}
          at {updated.toLocaleTimeString()}
        </Text>
        <Spacer />
        <Can I="update" an="strategy">
          <ButtonGroup>
            <SendReportButton report={report} />
            <Button as={Link} to="edit" variant="tertiary">
              Edit
            </Button>
          </ButtonGroup>
        </Can>
        <DrawerCloseButton />
      </DrawerHeader>
      <ReportContent report={report} />
    </Stack>
  )
}

export default Show
