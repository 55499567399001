import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'

import { ToggleInput } from '@app/shared/rawForms'
import type { Report } from '@graphql/types'

interface Props {
  report?: Pick<Report, 'hideChangelog'>
}

const HideSectionsInput: FC<Props> = ({ report }) => (
  <Stack spacing={2}>
    <Text mb={2} variant="section-header">
      Section Visibility
    </Text>
    <ToggleInput
      data-cy="hide-changelog-section"
      labelSize="sm"
      label="Hide changelog section"
      name="hideChangelog"
      defaultValue={report?.hideChangelog}
    />
  </Stack>
)

export default HideSectionsInput
