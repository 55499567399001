import { subject } from '@casl/ability'
import isNull from 'lodash/isNull'
import omitBy from 'lodash/omitBy'
import { defer, Outlet } from 'react-router-dom'
import type { LoaderFunction, RouteObject } from 'react-router-dom'

import recurringRoutes from './recurringReportConfigurations'

import ChangelogEntityEdit from '@app/pages/reports/components/changelog/edit'
import ChangelogEntityShow from '@app/pages/reports/components/changelog/show'
import Edit from '@app/pages/reports/edit'
import List from '@app/pages/reports/list'
import ReportsDrawer from '@app/pages/reports/reportsDrawer'
import Show from '@app/pages/reports/show'
import { updateEvent } from '@app/routes/events'
import { useStore } from '@app/store'
import { requiresAuthorization } from '@app/utils/auth'
import { loaderQuery } from '@graphql/client'
import { Report, Reports, ReportChangelog, ReportChangelogEntity } from '@graphql/documents/report.graphql'
import type { ReportChangelogQuery, ReportsQuery, ReportsQueryVariables } from '@graphql/queries'

const loadReports = async ({ request, params }) => {
  const url = new URL(request.url)
  const page = parseInt(url.searchParams.get('page'), 10) || 1
  const limit = parseInt(url.searchParams.get('limit'), 10) || null
  const { strategyId } = params

  const { addObjectPage } = useStore.getState()

  const variables: ReportsQueryVariables = { ...omitBy({ page, limit }, isNull), strategyId }

  const result = await loaderQuery<ReportsQuery, ReportsQueryVariables>(Reports, variables)
  const { collection = [], metadata } = result.data.reports
  addObjectPage('report', collection, metadata)

  return defer(result.data)
}

const loadReport = async ({ params }) => {
  const { reportId } = params
  const variables = { id: reportId }

  const resp = await loaderQuery(Report, variables)

  const report = resp?.data?.report

  return { report }
}

const loadReportChangelog: LoaderFunction = async ({ params }): Promise<{ report: ReportChangelogQuery['report'] }> => {
  const { reportId, changelogPage: page } = params

  const variables = { reportId, page: Number(page) }

  const resp = await loaderQuery(ReportChangelog, variables)
  const report = resp?.data?.report

  return { report }
}

const loadChangelogEntity = async ({ params }) => {
  const { entityId } = params

  const { data } = await loaderQuery(ReportChangelogEntity, { entityId })

  const entity = data?.entity

  return defer({ entity })
}

const authorizeEdit = async ({ params }) => {
  const deferred = await loadChangelogEntity({ params })

  const entity = deferred?.data?.entity

  const sub = subject('event', entity)

  await requiresAuthorization('update_from_changelog', sub)

  return { entity }
}

const reportShowChangelogEntity = 'reportShowChangelogEntity'
const reportEditChangelogEntity = 'reportEditChangelogEntity'
export const reportEntity = (id: string) => ({
  path: ':entityId',
  element: <Outlet />,
  id,
  loader: loadChangelogEntity,
  children: [
    {
      index: true,
      element: <ChangelogEntityShow routeId={id} />
    },
    {
      path: 'edit',
      loader: authorizeEdit,
      element: <ChangelogEntityEdit routeId={id} />,
      action: ({ request, params }) => {
        switch (request.method) {
          case 'POST':
            return updateEvent({ request, params })
          default:
            return null
        }
      }
    }
  ]
})

const sections = [
  {
    path: 'changelog/:changelogPage',
    loader: loadReportChangelog,
    element: <Outlet />
  }
]

const routes: RouteObject = {
  path: 'reports',
  element: (
    <ReportsDrawer>
      <Outlet />
    </ReportsDrawer>
  ),
  loader: loadReports,
  children: [
    {
      index: true,
      element: <List />
    },
    recurringRoutes,
    {
      path: ':reportId',
      element: <Outlet />,
      children: [
        {
          index: true,
          loader: loadReport,
          element: <Show />
        },
        ...sections,
        reportEntity(reportShowChangelogEntity),
        {
          path: 'edit',
          element: <Outlet />,
          children: [
            {
              index: true,
              loader: loadReport,
              element: <Edit />
            },
            ...sections,
            reportEntity(reportEditChangelogEntity)
          ]
        }
      ]
    }
  ]
}

export default routes
