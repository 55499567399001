import type { FC } from 'react'

import ConfirmPopover from '@app/shared/confirmPopover'
import { useRecurringReportConfigurationDeleteMutation } from '@graphql/queries'

type Props = {
  recurringReportConfigurationId: string
}

const RecurringReportConfigurationDelete: FC<Props> = ({ recurringReportConfigurationId }) => {
  const [, deleteObject] = useRecurringReportConfigurationDeleteMutation()

  const onSubmit = async () => deleteObject({ input: { recurringReportConfigurationId } })

  return (
    <ConfirmPopover
      onConfirm={onSubmit}
      header="Delete recurring report"
      body="Are you sure you want to delete this recurring report? You won&#39;t be able to undo this."
      confirmButtonText="Delete recurring report"
    >
      Delete recurring report
    </ConfirmPopover>
  )
}

export default RecurringReportConfigurationDelete
