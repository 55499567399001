import { Stack, Text } from '@chakra-ui/react'
import type { FC } from 'react'
import { NavLink } from 'react-router-dom'

import ReportPeriod from './reportPeriod'

import type { Report } from '@graphql/types'

type CReport = Pick<Report, 'id' | 'name' | 'startDate' | 'endDate'>

type ListItemProps = {
  report: CReport
}

type ListProps = {
  reports: CReport[]
}

const DrawerListItem: FC<ListItemProps> = ({ report }) => (
  <Stack
    as={NavLink}
    px={4}
    py={3}
    _hover={{ bgColor: 'bg.subtle' }}
    _activeLink={{ bgColor: 'bg.muted' }}
    spacing={1}
    to={report.id}
  >
    <Text variant="section-header">{report.name}</Text>
    <ReportPeriod report={report} />
  </Stack>
)

const DrawerList: FC<ListProps> = ({ reports }) => (
  <Stack overflowY="auto">
    {reports.map((report) => (
      <DrawerListItem key={report.id} report={report} />
    ))}
  </Stack>
)

export default DrawerList
